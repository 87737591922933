<template>
  <div class="footer-box">
    <myHeader class="my-header"></myHeader>

    <div class="detail-container">

      <div class="detail-box">

        <div class="box-top">
          <div class="img-box">
            <img class="detail-img" src="../assets/distr/P01.png" alt="">
          </div>
          <div class="info-box">
            <div class="info-title">脂肪浓缩胶囊</div>
            <div class="info-price">$40</div>
            <div>90 Day Money-Back Guarantee</div>
            <div>We know you’ll love it! If not, it’s on us.</div>
            <div>Scalp Serum + combines innovative, traditional, hydrating, and soothing ingredients to support hair
              growth and scalp health. Our patent-pending, reusable applicator evenly dispenses serum while massaging
              the scalp to enhance blood circulation and promote hair growth.</div>
          </div> 
        </div>
      </div>


    </div>
    <myFooter></myFooter>
  </div>   


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'LoginPage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {

    }
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail-container {
  margin-top: 126px;
  background-color: #f2f2f2;

  .detail-box {
    margin: 0px auto;

    padding: 20px 0;
    width: 1200px;


    .box-top {
      display: flex;

      .img-box {
        .detail-img {
          width: 435px;
          height: 435px;
          background-color: #eee;
        }


      }

      .info-box {
        margin: 0 20px;

        .info-title {
          margin-top: 50px;
          line-height: 70px;
          font-size: 28px;
          font-weight: 700;
        }

        .info-price {
          font-size: 28px;
        }
      }
    }
  }
}
</style>