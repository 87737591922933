<template>
    <div class="footer-box">
        <myHeader class="my-header"></myHeader>


        <div>
            <div class="model-1">
                <div class="model-1-title">Best Sellers</div>


                <div class="p-list">

                    <div class="p-item" v-for="(item, index) of list" :key="index">
                        <img class="p-item-img" :src="require('@/assets/' + item.img)" alt="vesitin">
                        <h3 class="p-item-title">{{ item.name }}</h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>


                </div>
            </div>
        </div>


        <myFooter></myFooter>
    </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
    name: 'HomePage',
    components: {
        myFooter,
        myHeader
    },
    data() {
        return {
            list: [
                { img: 'distr/P16.png', name: '胰岛果苦瓜胶囊Pro Max', price: '47.00', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
                { img: 'distr/P17.png', name: '茄子粉槲皮素肺康素胶囊Pro Max', price: '109.00', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                { img: 'distr/P18.png', name: '西芹鹅肌肽尿酸复合片Pro Max', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                { img: 'distr/P19.png', name: '麦芽补硒复合速溶片Pro Max', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                { img: 'distr/P20.png', name: '纳豆激酶水蛭素胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },


                { img: 'distr/P22.png', name: '高活性复合维生素B12Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P24.png', name: '神经酸胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P25.png', name: '褪黑素精华复合速溶片plus', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P26.png', name: '蒲公英忘忧草结节素胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P27.png', name: '熊果蔓越莓强力通石素胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P28.png', name: '奶蓟草姜黄护肝片Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P29.png', name: '月见草鹿胎素大豆异黄酮软护巢胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P30.png', name: 'NAD+PQQ麦角硫因细胞年轻态胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },

                // { img: 'distr/P01.png', name: '脂肪酶浓缩胶囊', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
                // { img: 'distr/P02.png', name: '男性刺蒺藜片', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                // { img: 'distr/P03.png', name: '脂肪酶浓缩胶囊', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
                // { img: 'distr/P04.png', name: '辅酶 Q10', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                // { img: 'distr/P05.png', name: '叶黄素片', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
                // { img: 'distr/P06.png', name: '纳豆胶囊', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                // { img: 'distr/P07.png', name: '磷脂酰丝氨酸胶囊', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
                // { img: 'distr/P08.png', name: '甲腺胶囊', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                // { img: 'distr/P09.png', name: '牡蛎胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                // { img: 'distr/P10.png', name: '凝胶糖果', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                // { img: 'distr/P11.png', name: '淫羊藿胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                // { img: 'distr/P12.png', name: '刺蒺藜胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                // { img: 'distr/P13.png', name: '芹菜籽酸樱桃片', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                // { img: 'distr/P14.png', name: '槲皮素胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                // { img: 'distr/P15.png', name: '芹菜籽酸樱桃片', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' }
            ],
        }
    },
    methods: {
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.model-1 {
    background-color: #F2F2F2;

    .model-1-title {
        padding-top: 60px;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        margin: 10px 0 20px 0;
        line-height: 50px;
    }

    .p-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 30px;

        .p-item {
            // height: 484px;
            background: white;
            margin: 5px;
            padding: 10px;
            font-size: 18px;

            .p-item-yj {
                text-decoration: line-through;
            }

            .p-item-xj {
                font-weight: 700;
                margin-left: 10px;
            }

            .p-item-title{
                overflow: hidden;
                height: 28px;
                width: 318px;
            }


            .p-item-img {
                width: 318px;
                height: 318px;
            }

            .p-item-btn {
                background-color: black;
                height: 39px;
                color: white;
                text-align: center;
                line-height: 39px;
                border-radius: 5px;
                margin: 10px 0;
            }
        }
    }
}


@media (max-width: 768px) {
    .banner {
        width: 100vw;
    }


    .p-list {
        padding: 0;
        flex-wrap: wrap;
    }

    .p-item-img {
        width: 90vw !important;
        height: auto !important;
    }

    .footer {
        height: 138px;
    }
}
</style>