<template>
    <div class="footer-box">
        <div class="other-infor">
            <div class="other-info-left other-info-cell">
                <div class="other-info-title">contact</div>
                <div> 863 Lai Chi Kok Road, Cheung Sha Wan HongKong</div>
                <div>+1 (800) 480-4319</div>
                <div>info@vesitin.com</div>
            </div>
            <div class="other-info-mid other-info-cell">
                <div class="other-info-title">Follow Us</div>
                <div class="contact-box">
                    <div class="iconfont icon-facebook contact-item"></div>
                    <div class="iconfont icon-Youtube contact-item"></div>
                    <div class="iconfont icon-instagram-line contact-item"></div>
                    <div class="iconfont icon-douyin-  contact-item"></div>
                </div>

                <div class="other-info-title">We Accept</div>
                <div class="contact-box">
                    <div class="iconfont icon-yinhangqia contact-item"></div>
                    <div class="iconfont icon-paypal contact-item"></div>
                    <div class="iconfont icon-gugepay contact-item"></div>
                    <div class="iconfont icon-applepay contact-item"></div>
                </div>
            </div>
            <div class="other-info-right other-info-cell">
                <div class="other-info-title">Information</div>
                <div>
                    <div>Privacy Policy</div>
                    <div>Cookie Policy</div>
                    <div>Shipping, Returns, and Refunds Policy</div>
                    <div>90 Days Money Back Guarantee</div>
                </div>
            </div>
        </div>



        <div class="jsns-box">
            <a class="jsns"
                href="https://baike.baidu.com/item/%E6%B1%9F%E8%8B%8F%E5%A8%9C%E8%8E%8E%E5%8C%BB%E7%96%97%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/64612738?fr=ge_ala">江苏娜莎医疗有限公司</a>
            版权所有

            <a href="https://beian.miit.gov.cn/">苏ICP备2024115561号-1</a>
        </div>

        <div class="jsns-box">
            <a class="jsns" href="https://beian.mps.gov.cn/#/query/webSearch">江苏娜莎医疗有限公司</a>
            版权所有

            <a href="https://beian.miit.gov.cn/">苏公网安备32132202001291号</a>
        </div>


        <div class="footer">
            Copyright © 2024 VESITIN. All rights reserved. | The content on this website is protected by copyright and
            other
            intellectual property laws. | A Brand By LUNA INC
        </div>
    </div>


</template>


<script>
export default {
    name: 'myFooter',
    data() {
        return {}
    }
}

</script>


<style lang="less" scoped>
.footer {
    height: 46px;
    background-color: #e4e4e4;
    line-height: 46px;
    text-align: center;
    font-size: 14px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.other-infor {
    background-color: #F3F3F1;
    display: flex;
    padding: 50px 0;
    justify-content: space-around;
    flex-wrap: wrap;
}

.contact-box {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.other-info-mid {
    text-align: center;
}

.contact-item {
    font-size: 24px;

    padding: 0 10px;
}

.other-info-cell {
    margin-bottom: 20px;

    .other-info-title {
        line-height: 30px;
        text-align: center;
        font-size: 22px;
    }
}

.jsns-box {
    text-align: center;
    height: 80px;
    font-size: 13px;
    color: black;

    .jsns {
        height: 80px;
        line-height: 80px;
        text-align: center;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 768px) {

    .footer {
        height: 138px;
    }

}
</style>