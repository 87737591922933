<template>
  <div class="page">

    <myHeader class="my-header"></myHeader>


    <div class="banner-box">
      <img class="banner" src="../assets/banner1.jpg" alt="">
    </div>

    <!-- <div class="model-1">
      <div class="model-1-title">Best Sellers</div>


      <div class="p-list">

        <div class="p-item" v-for="(item, index) of list" :key="index">
          <img class="p-item-img" :src="require('@/assets/' + item.img)" alt="vesitin">
          <h3>{{ item.name }}</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>

      </div>
    </div> -->



    <div class="honor">
      <div>
        <div class="honoe-title">Certifications</div>
        <div class="honor-box">
          <div v-for="(item, index) of Certifications" :key="index">
            <img class="cer-img" :src="require('@/assets/' + item.img)" alt="">
          </div>
        </div>
      </div>

      <div>
        <div class="honoe-title">Safely Formulated</div>
        <div class="honor-box">
          <div v-for="(item, index) of Safely" :key="index">
            <img class="cer-img" :src="require('@/assets/' + item.img)" alt="">
          </div>
        </div>
      </div>
    </div>






    <myFooter></myFooter>



    <div class="back-box" @click="backTop">
      <div class="iconfont icon-xiangshang1"></div>
    </div>


  </div>
</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'HomePage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {
      list: [
        { img: 'distr/P01.png', name: 'Lipase Fat Burnuing', price: '47.00', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
        { img: 'distr/P02.png', name: 'TRIBULUS plus ZINC', price: '109.00', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P03.png', name: 'Intestinal GUT SUPPORT', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P04.png', name: 'CO Q10', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P05.png', name: 'EYE FIT', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' }
      ],
      Certifications: [
        { img: 'FDA.png' },
        { img: 'h02.jpg' },
        { img: 'h03.jpg' },
        { img: 'h04.jpg' },
        { img: 'h05.jpg' }
      ],
      Safely: [
        { img: 'h2-1_01.jpg' },
        { img: 'h2-1_02.jpg' },
        { img: 'h2-1_03.jpg' },
        { img: 'h2-1_04.jpg' },
        { img: 'h2-1_05.jpg' },
        { img: 'h2-1_06.jpg' }
      ]
    }
  },
  mounted() {
    console.log(this.$axios);
    // this.$axios({
    //   method: 'get',
    //   url: '/api/feedback/feedbackList',
    //   data: {}
    // }).then(res => {
    //   console.log(res);
    // })
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped lang="less">
.banner-box {
  text-align: center;
  background-color: #e9e9eb;
  margin-top: 126px;
}

.model-1 {
  background-color: #F2F2F2;

  .model-1-title {
    padding-top: 60px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin: 10px 0 20px 0;
    line-height: 50px;
  }

  .p-list {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;

    .p-item {
      // height: 484px;
      background: white;
      margin: 5px;
      padding: 10px;
      font-size: 18px;

      .p-item-yj {
        text-decoration: line-through;
      }

      .p-item-xj {
        font-weight: 700;
        margin-left: 10px;
      }


      .p-item-img {
        width: 318px;
        height: 318px;
      }

      .p-item-btn {
        background-color: black;
        height: 39px;
        color: white;
        text-align: center;
        line-height: 39px;
        border-radius: 5px;
        margin: 10px 0;
      }
    }
  }
}



.honor {
  .honoe-title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    padding: 20px 0;
  }

  .honor-box {
    margin: 10px 0;
    display: flex;
    padding: 0 10vw;
    justify-content: space-around;
    flex-wrap: wrap;

    .cer-img {
      width: 117px;
      height: auto;
    }
  }

}



.back-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 5px;
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .banner {
    width: 100vw;
  }


  .honor {
    .honoe-title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      padding: 20px 0;
    }

    .honor-box {
      margin: 10px 0;
      display: flex;
      padding: 0 10vw;
      justify-content: space-around;
      flex-wrap: wrap;

      .cer-img {
        width: 90px;
        height: auto;
      }
    }

  }


  .p-list {
    padding: 0;
    flex-wrap: wrap;
  }

  .p-item-img {
    width: 90vw !important;
    height: auto !important;
  }

  .footer {
    height: 138px;
  }
}
</style>
