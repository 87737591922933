<template>
  <div class="footer-box">
    <myHeader class="my-header"></myHeader>

    <div class="art-container">


      <div class="art-list">

        <div class="art-item" v-for="(item, index) in list" :key="index" @click="navigateTo">
          <img class="item-p-img item-p-cell" :src="require('@/assets/' + item.img)" alt="">
          <div class="item-info">
            <div class="item-p-name item-p-cell">{{ item.name }}</div>
            <div class="item-price-box">
              <div class="item-p-price item-p-cell">{{ item.place }}</div>
              <div class="item-p-spec item-p-cell">{{ item.spec }}</div>
            </div>

            <div class="item-p-pattern item-p-cell">{{ item.pattern }}</div>
            <!-- <div class="item-p-place item-p-cell">{{ item.place }}</div> -->
          </div>

        </div>


      </div>
    </div>
    <myFooter></myFooter>
  </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'HomePage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {
      list: [
        { img: 'distr/P01.png', name: '脂肪酶浓缩胶囊', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
        { img: 'distr/P02.png', name: '男性刺蒺藜片', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P03.png', name: '脂肪酶浓缩胶囊', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
        { img: 'distr/P04.png', name: '辅酶 Q10', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P05.png', name: '叶黄素片', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
        { img: 'distr/P06.png', name: '纳豆胶囊', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P07.png', name: '磷脂酰丝氨酸胶囊', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
        { img: 'distr/P08.png', name: '甲腺胶囊', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P09.png', name: '牡蛎胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
        { img: 'distr/P10.png', name: '凝胶糖果', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
        { img: 'distr/P11.png', name: '淫羊藿胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
        { img: 'distr/P12.png', name: '刺蒺藜胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
        { img: 'distr/P13.png', name: '芹菜籽酸樱桃片', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
        { img: 'distr/P14.png', name: '槲皮素胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
        { img: 'distr/P15.png', name: '芹菜籽酸樱桃片', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' }
      ]
    }
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },


    // navigateTo(e){
    //   this.$route.push({
    //     path:''
    //   })
    //   })
    // }
  }
}
</script>


<style lang="less">
.art-container {
  margin-top: 126px;
  background-color: #F2F2F2;

  .art-list {
    padding: 20px 5%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .art-item {
      margin: 10px;
      background-color: white;
      width: 300px;
      border-radius: 10px;

      // display: flex;
      // justify-content: space-around;
      .item-p-img {
        width: 300px;
        height: 300px;
      }

      .item-info {
        padding: 0 10px;

        margin-bottom: 10px;

        .item-price-box {
          display: flex;
          justify-content: space-between;
        }

        .item-p-name {
          text-align: center;
          font-weight: 700;
        }
      }


    }

    .art-item:hover {
      background-color: rgb(233, 244, 245);
    }
  }
}

@media (max-width: 768px) {
  .art-list {
    font-size: 10px;
    padding: 10px 10px !important;
    display: flex;

    .art-item {
      //
      justify-content: space-around;
      flex-wrap: nowrap;
      overflow: scroll;

      width: 180px !important;
      margin: 10px 0 !important;

      .item-p-cell {
        line-height: 30px !important;
        font-size: 14px;
      }

      .item-p-name {
        font-size: 18px;
      }

      .item-p-img {
        width: 180px !important;
        height: 180px !important;
      }
    }
  }
}
</style>